import React from 'react'
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

export default function ServiceList() {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')} p={4}>
      <Stack
        mt={35}
        spacing={4}
        as={Container}
        maxW={'3xl'}
        textAlign={'center'}
      >
        <Heading fontSize={'3xl'}>Szolgáltatásaink</Heading>
        <Text color={'gray.600'} fontSize={'xl'}>
          Tevékenységünk üzleti folyamatokra épülő, korszerű
          létesítménygazdálkodás, az épületek, területek értékének és állagának
          hosszútávon történő megőrzése, magas minőségi és szakmai színvonalon.
        </Text>
      </Stack>

      <Container maxW={'6xl'} mt={10} mb={35}>
        <SimpleGrid mb={25} columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
          {features.map(feature => (
            <HStack key={feature.id} align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={'gray.600'}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  )
}

const features = [
  {
    id: 1,
    title: 'Üzemeltetés',
    text: 'Irodaházak, lakóparkok, társasházak teljeskörű karbantartása.',
    image: '../images/building2.jpeg',
  },
  {
    id: 2,
    title: '24 órás portaszolgálat',
    text: 'A nap huszonnégy órájában portaszolgáltatás.',
    image: '../images/building2.jpeg',
  },
  {
    id: 3,
    title: 'Kamerarendszerek telepítése és üzemeltetése',
    text: 'A videó-megfigyelő rendszer hálózatok-kiépítése, karbantartása.',
    image: '../images/building2.jpeg',
  },
  {
    id: 4,
    title: 'Irodaházak, irodák, egyéb épületek takarítása',
    text: 'Mindenre kiterjedő professzionális takarítási szolgáltatás.',
    image: '../images/building2.jpeg',
  },
  {
    id: 5,
    title: 'Zöldterület-kezelés',
    text: 'Széleskörű, mindenre kiterjedő kertészeti szolgáltatás.',
    image: '../images/building2.jpeg',
  },
  {
    id: 6,
    title: 'Egyéb épület-, ipari takarítás',
    text: 'Irodaházak, lakóparkok teljeskörű külső tisztítása alpin technikával.',
    image: '../images/building2.jpeg',
  },
]
