import * as React from 'react'
import Layout from '../components/layout'
import SplitScreen from '../components/hero'
import ServiceList from '../components/services'
import BasicStatistics from '../components/why'
import Simple from '../components/contact'
import ContactCard from '../components/contactCard'

const IndexPage = () => {
  return (
    <Layout title="Elitehome - Főoldal">
      <div style={{ minHeight: '50vw' }}>
        <SplitScreen />
        <ServiceList />
        <BasicStatistics />
        <Simple />
        <ContactCard />
      </div>
    </Layout>
  )
}

export default IndexPage
