import React from 'react'
import { useState, useEffect } from 'react'
import {
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Heading,
  Container,
  Flex,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import { sendEmail } from '../components/utils'

export default function Simple() {
  const [formData, setFormData] = useState({ name: '', email: '' })
  const [state, setState] = useState('idle')
  const [sent, setSent] = useState(false)

  const shouldSubmit = state === 'submitting' && !sent

  useEffect(() => {
    async function fetchData() {
      if (formData.name && formData.email && !sent) {
        const res = await sendEmail(formData.name, formData.email, 'contact')
        setSent(true)
        if (res.data.status === 'success') {
          setState('success')
        }
      }
    }

    fetchData()
  }, [shouldSubmit])

  return (
    <Flex
      minH={'60vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Container
        maxW={'3xl'}
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        boxShadow={'xl'}
        rounded={'lg'}
        p={16}
        direction={'column'}
      >
        <Heading
          as={'h2'}
          fontSize={{ base: 'xl', sm: '2xl' }}
          textAlign={'center'}
          mb={5}
          style={{ marginBottom: 40 }}
        >
          KÉRJE AJÁNLATUNKAT!
        </Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          as={'form'}
          spacing={'12px'}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormControl isRequired>
            <Input
              variant={'solid'}
              borderWidth={1}
              color={'gray.800'}
              _placeholder={{
                color: 'gray.400',
              }}
              borderColor={useColorModeValue('gray.300', 'gray.700')}
              id={'name'}
              type={'text'}
              required
              placeholder={'Név'}
              aria-label={'Név'}
              value={formData.name}
              onChange={e => setFormData({ ...formData, name: e.target.value })}
              style={{ marginBottom: 20 }}
            />
            <Input
              variant={'solid'}
              borderWidth={1}
              color={'gray.800'}
              _placeholder={{
                color: 'gray.400',
              }}
              borderColor={useColorModeValue('gray.300', 'gray.700')}
              id={'email'}
              type={'email'}
              required
              placeholder={'E-mail cím'}
              aria-label={'Email'}
              value={formData.email}
              onChange={e =>
                setFormData({ ...formData, email: e.target.value })
              }
              style={{ marginBottom: 20 }}
            />
          </FormControl>
        </Stack>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginTop: 15,
          }}
        >
          <Button
            disabled={
              (!formData.name && !formData.email) || state === 'success'
            }
            onClick={e => {
              e.preventDefault()
              setState('submitting')
            }}
            isLoading={state === 'submitting'}
            colorScheme={state === 'success' ? 'green' : 'red'}
            w="50%"
          >
            {state === 'success' ? <CheckIcon /> : 'Ajánlatot kérek'}
          </Button>
        </div>
      </Container>
    </Flex>
  )
}
