import React from 'react'
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  Link,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

export default function SplitScreen() {
  return (
    <Stack minH={'90vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={6} w={'full'} maxW={'lg'}>
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: useBreakpointValue({ base: '20%', md: '30%' }),
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}
            >
              Építményüzemeltetés
            </Text>
            <br />{' '}
            <Text color={'red.400'} as={'span'}>
              24/7
            </Text>{' '}
          </Heading>
          <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
            Képzett szakemberekkel állunk kapcsolatban minden karbantartási
            területen az üzemeltetésben.
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Link style={{ textDecoration: 'none' }} href="/services">
              <Button
                rounded={'full'}
                bg={'red.400'}
                color={'white'}
                _hover={{
                  bg: '#eb4d4b',
                }}
              >
                Szolgáltatásaink
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <StaticImage
          src="../images/building1.jpeg"
          placeholder="blurred"
          objectFit="cover"
          alt="hero"
          loading="eager"
        />
      </Flex>
    </Stack>
  )
}
